import React from "react"
import PropTypes from "prop-types"
import JobDetails from "../../../../../components/JobDetail/JobDetails"
import { useStaticQuery, graphql } from "gatsby"
import { labels } from "../../../../../constants/label.constants"
import { gTagScriptConsent } from "../../../../../components/Scripts/Gtm.Head"

const JobDetailsPage = () => {
  var dataLayer = useStaticQuery(
    graphql`
      {
        allContentfulDataLayer {
          nodes {
            environment
            content {
              page {
                url
                title
              }
              GTM {
                Lytics
                Tiktok
                Twitter
                Snapchat
                SiteBrand
                FacebookID
                SiteLocale
                SiteCountry
                SiteLanguage
                ConsentOverlay
                SiteEnvironment
                ConsentOverlayID
                SiteLocalContainer
                SiteTechnicalAgency
                GoogleAnalyticsLocal
                SitePrivacyProtection
                GoogleAnalyticsReportingView
                GoogleAnalyticsGA4MeasurementID
                GoogleAnalyticsOptimizeContainerID
              }
              content {
                type
                category
              }
            }
          }
        }
        allContentfulPage(filter: {pageType:{in:["Job Details Page"]}}) {
          nodes {
            pageType
            topDisclaimer{topDisclaimer}
            hasMoreLessButton
            slug
            contentful_id
            seoData {
              metaTitle
              metaDescription
              metaKeywords
              ogType
              ogTitle
              ogUrl
              ogImage {
                url
              }
              featuredImage {
                url
              }
              title
              twitterDescription
              twitterTitle
              pageType
              noIndex
              noFollow
              languageCode
            }
            content {
              ... on ContentfulEntry {
                contentful_id
                __typename
              }
            }
            isChevronIcon
            scriptId
            hasOneTrustScipt
          }
        }
      }
    `
  )
  // GTM custom dataLayer Object based on environment
  if (typeof window !== "undefined") {
    const environment = process.env.GATSBY_ACTIVE_ENV
    window.$ = window.jquery = window.jQuery = require("jquery")
    const seoData = {
      metaTitle: `${labels.metaTitle}`,
      title: `${labels.metaTitle}`,
      pageType: "Job Detail Page",
    }
    let dataLayerEnv
    dataLayer?.allContentfulDataLayer?.nodes?.map(e => {
      if (e?.environment == environment) {
        dataLayerEnv = e?.content?.GTM
      }
    })
    const dataLayerObj = {
      GTM: dataLayerEnv,
      page: {
        url: window.location.href,
        title: seoData?.title || seoData?.metaTitle,
      },
      content: {
        type: seoData.pageType || "others",
        category: "",
      },
    }
    window.gtmDataLayer = dataLayerObj
  }
  const seoDatas = dataLayer?.allContentfulPage?.nodes[0]?.seoData
  return <JobDetails seoDatas={seoDatas} />
}

export const Head = () => {
  const dataObject = typeof window !== "undefined" ? window?.gtmDataLayer : {}
  return (
    <>
      <script
        id="first-unique-id"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `var MdlzdataLayer = ${JSON.stringify(dataObject)};`,
        }}
      />
      <script
        async
        type="text/javascript"
        dangerouslySetInnerHTML={{ __html: gTagScriptConsent }}
      />
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.4/jquery.min.js" />
      <script src="https://cdn.jsdelivr.net/npm/js-cookie@3.0.1/dist/js.cookie.min.js"></script>
    </>
  )
}

export default JobDetailsPage
